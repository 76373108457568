import React from "react";

const TextArea = ({
  name,
  label,
  value,
  onChange,
  className,
  placeholder,
}) => {
  return(
    <div className={`mb-4 ${className || ""}`}>
      { label && <label className="form-label">{ label }</label>}
      <textarea
        type="text"
        className="form-control"
        placeholder={placeholder}
        name={name}
        value={value || ""}
        onChange={onChange}
      />
    </div>
  )
}

export default TextArea
