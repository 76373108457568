const formatCurrency = (cents) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return formatter.format(cents / 100)
}

export default formatCurrency
