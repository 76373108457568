import { useState, useEffect } from "react"

import request from "~/lib/request"
import { upperFirst } from "lodash"

const useDataFetch = (defaultState, url, key, callback = () => {}) => {
  const [state, setState] = useState(defaultState || {})
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const req = request.get(url)
    req.then((data) => {
      setState(data)
      setLoaded(true)
      callback(data)
    })
  }, [])

  const methodName = `set${upperFirst(key)}`

  return({
    loaded: loaded,
    [key]: state,
    [methodName]: setState,
  })
}

export default useDataFetch
