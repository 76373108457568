import React from "react";
import useDataFetch from "~/hooks/useDataFetch"

const TextInput = ({
  name,
  label,
  value,
  disabled,
  onChange,
  className,
  placeholder,
}) => {
  return(
    <div className={`mb-4 ${className || ""}`}>
      <label className="form-label">{ label }</label>
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        name={name}
        value={value || ""}
        onChange={onChange}
        disabled={disabled || false}
      />
    </div>
  )
}

export default TextInput
